
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Raleway:wght@500;600;700&display=swap");
@font-face {
  font-family: 'conthrax-sb';
  src: local('conthrax-sb'), url(./font/conthrax-sb.ttf) format('truetype');
}

:root {
  --header-height: 3rem;

  --hue-color: 190;
  --test-color : hsl(141,100%,25%);

  --first-color: hsl(141,100%,25%);
  --first-color-second:  hsl(141,100%,25%);
  --first-color-alt: rgb(3, 93, 35);
  --title-color: hsl(197,36%,9%);
  --text-color: hsl(var(--hue-color), 24%, 35%);
  --text-color-light: hsl(var(--hue-color), 8%, 60%);
  --input-color: hsl(var(--hue-color), 24%, 97%);
  --body-color: hsl(var(--hue-color), 100%, 99%);
  --white-color: #FFF;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 75%);


  --body-font: 'Open Sans', sans-serif;
  --title-font: 'Raleway', sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;


  --font-medium: 500;
  --font-semi-bold: 600;


  --mb-0-25: .25rem;
  --mb-0-5: .5rem;
  --mb-0-75: .75rem;
  --mb-1: 1rem;
  --mb-1-25: 1.25rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

 
  --z-tooltip: 10;
  --z-fixed: 100;


  --img-transition: .3s;
  --img-hidden: hidden;
  --img-scale: scale(1.1);
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
}
@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.75rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }

}

/*========== Variables Dark theme ==========*/
body.riri-sun-line {
  --first-color-second: hsl(var(--hue-color), 54%, 12%);
  --title-color: hsl(var(--hue-color), 24%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(197,36%,9%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}





/*========== Button Dark/Light ==========*/
.nav__dark {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  position: absolute;
  left: 3rem;
  bottom: 4rem;
}

.change-theme,
.change-theme-name {
  color: var(--text-color);
}

.change-theme {
  cursor: pointer;
  font-size: 1rem;
}

.change-theme-name {
  font-size: var(--small-font-size);
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

.gradient-background{
  height: 100%;
  width: 100%;
  background: #270808;
  position:absolute;
  top:0; left: 0;
  z-index: -1;
  overflow:hidden;
}

.gradient-background .animated-box{
  position: absolute;
  display: block;
  pointer-events: none;
  border-radius: 0.3rem;
  animation: animate 3s linear infinite;
  background-color: aqua;
  background-size: contain;
}

@keyframes animate{
  0%{
      opacity: 0;
      transform:scale(0) translateY(-200%) rotate(0deg);
  }
  10%{
      opacity: 1;
  }
  90%{
      opacity: 1;
  }
  100%{
      opacity: 0;
      transform:scale(1) translateY(-500%) rotate(360deg);
  }
}


h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  font-family: var(--title-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
vi1deo {
  max-width: 100%;
  height: auto;
}

.nav__logo {
  max-width: 8rem;
  margin-top: 0.6rem;
}

button,
input {
  border: none;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

button {
  cursor: pointer;
}

input {
  outline: none;
}

.main {
  overflow-x: hidden;
}
.icon__presu{
  height: 2.5rem;
  width: 2.5rem;
  color:hsl(197,36%,9%) ;
}


/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4.5rem 0 2.5rem;
}

.section__title {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: var(--mb-2);
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1);
  margin-right: var(--mb-1);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

/*=============== HEADER ===============*/
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color:  var(--white-color);
  border-radius: 0rem 0rem 1.5rem 1.5rem;
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  
 
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
}

.nav__logo {
  font-weight: var(--font-semi-bold);

}

.nav__toggle {
  font-size: 1.2rem;
  cursor: pointer;
}

.nav__menu {
  position: relative;
}

@media screen and (max-width: 767px) {
  .nav__menu {
    position: fixed;
    background-color: var(--body-color);
    
    right: -100%;
    width: 70%;
    height: 100%;
    box-shadow: -1px 0 4px rgba(14, 55, 63, 0.15);
    padding: 3rem;
    transition: .4s;
    top: 3rem;
  }
}

.nav__list {
  display: flex;
  flex-direction:column;
  row-gap: 2rem;
  
}

.nav__link {
  color: var(--text-color-light);
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
}

.nav__link:hover {
  color: var(--text-color);
}

.nav__close {
  position: absolute;
  top: .75rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

/* show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header {
  background-color: var(--body-color);
  box-shadow: 0 0 4px rgba(14, 55, 63, 0.15);
}

.scroll-header .nav__logo,
.scroll-header .nav__toggle {
  color: var(--title-color);
}

/* Active link */
.active-link {
  position: relative;
  color: var(--title-color);
}

.active-link::before {
  content: '';
  position: absolute;
  background-color: var(--title-color);
  width: 100%;
  height: 2px;
  bottom: -.75rem;
  left: 0;
}

/*=============== HOME ===============
*/

.home__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 83%;
}
.home__banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: 83%;
}
.home__container__banner {
  position: relative;
  height: calc(65vh - var(--header-height));
  align-content: center;
  row-gap: 3rem;
}

.home__container {
  position: relative;
  height: calc(100vh - var(--header-height));
  align-content: center;
  row-gap: 3rem;
}

.home__data-subtitle,
.home__data-title,
.home__social-link,
.home__info {
  color: var(--white-color);
}

.home__data-subtitle {
  display: block;
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-75);
}

.home__data-title {
  font-size: var(--biggest-font-size);
  font-weight: var(--font-medium);
  font-family: conthrax-sb ;
  text-transform: uppercase;
}


.titles{
  font-size: var(--h1-font-size);
  font-weight: var(--font-medium);
  text-transform: uppercase;
  font-family: conthrax-sb ;
  color: #FFF;
}

.home__social {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.home__social-link {
  font-size: 1.2rem;
  width: max-content;
}

.home__info {
  background-color: hsl(129,83%,33%);
  display: flex;
  padding: 1.5rem 1rem;
  align-items: center;
  column-gap: .5rem;
  position: absolute;
  right: 0;
  bottom: 1rem;
  width: 228px;
  display: none;
}

.home__info-title {
  display: block;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-75);
}

.home__info-button {
  font-size: var(--normal-font-size);
}

.home__info-overlay {
  overflow: var(--img-hidden);
}

.home__info-img {
  width: 145px;
  transition: var(--img-transition);
  
}

.home__info-img:hover {
  transform: var(--img-scale);
}

/*=============== BUTTONS ===============*/

.button {
  display: inline-block;
  padding: 0.9rem 1.8rem;
  font-size: 16px;
  font-weight: 700;
  color: hsl(197,36%,9%);
  border: 3px solid hsl(141,100%,25%);
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
  font-family: inherit;
  border-radius: 3rem;
 }
 
 .button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(141,100%,25%);
  transform: translateX(-100%);
  transition: all .3s;
  z-index: -1;
 
 }
 
 .button:hover::before {
  transform: translateX(0);
  
 }
 .button:hover {
  
  color: rgb(250, 243, 243);
 }


.button--flex {
  display: flex;
  align-items: center;
  column-gap: .25rem;
}

.button--link {
  background: none;
  padding: 0;
}

.button--link:hover {
  background: none;
}

.btn-danger {
  color: tomato;
  background-color: #fff;
  border: 2px solid #fff;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-danger:hover {
  color: rgb(248, 80, 51);
border-bottom: 2px solid tomato;

}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: tomato;
  border-color: tomato;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-total{
  font-weight: var(--font-medium);
  font-size: var(--h3-font-size);
  padding: 0.7rem 1.5rem;
  margin-top: 1rem;
}
.btn__color{
  color: #FFF;
}
/*=============== ABOUT ===============*/
.about__data {
  text-align: center;
}

.about__container {
  row-gap: 2.5rem;
}

.about__description {
  margin-bottom: var(--mb-2);
}

.about__img {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
  
}

.about__img-overlay {
  overflow: var(--img-hidden);
  border-radius: 0.25rem;
}

.about__img-one {
  width: 130px;
  
}

.about__img-two {
  width: 300px;
  
}

.about__img-one,
.about__img-two {
  transition: var(--img-transition);
 
}

.about__img-one:hover,
.about__img-two:hover {
  transform: var(--img-scale);
 
}

/*=============== DISCOVER ===============*/

.place__container {
  grid-template-columns: repeat(1, max-content);
  justify-content: center;
}

.discover__card {
  position: relative;
  width: 280px ;
  height: 160px;
  overflow: var(--img-hidden);
  border-radius: 2rem;
}

.discover__data {
  position: absolute;
  height: 90%;
  top: 2rem;
  left: 2rem;
}

.discover__title,
.discover__description {
  color: var(--white-color);
}

.discover__title {
  font-size: var(--h1-font-size);
  height: 30px;
}

.discover__description {
  display: block;
  font-size: var(--normal-font-size);
   margin-top: 30px;
   height: 40px;
}
.discover__button{
 margin-top: 70px;
 color: #FFF;
}
.discover__img {
  transition: var(--img-transition);
  
}

.discover__img:hover {
  transform: var(--img-scale);
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: none;
}

.consultas{
  padding: 15px;
  color: gray;
}
.consultas:hover{
  color: whitesmoke;
}


/*=============== EXPERIENCE ===============*/
.experience__container {
  row-gap: 2.5rem;
  justify-content: center;
  justify-items: center;
}

.experience__content {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  justify-items: center;
  padding: 0 2rem;
}

.experience__number {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-5);
}

.experience__description {
  font-size: var(--small-font-size);
}

.experience__img {
  position: relative;
  padding-bottom: 2rem;
}

.experience__img-one,
.experience__img-two {
  transition: var(--img-transition);
  
}

.experience__img-one:hover,
.experience__img-two:hover {
  transform: var(--img-scale);
}

.experience__overlay {
  overflow: var(--img-hidden);
}

.experience__overlay:nth-child(1) {
  width: 263px;
  margin-right: 2rem;
}

.experience__overlay:nth-child(2) {
  width: 120px;
  position: absolute;
  top: 2rem;
  right: 0;
}

/*=============== VIDEO ===============*/
.video-file{
  max-width: 100%;
  height: auto;
  
}
.video__container {
  padding-bottom: 1rem;
}

.video__description {
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.video__content {
  position: relative;

}

.video__button {
  position: absolute;
  right: 1rem;
  bottom: -1rem;
  padding: 1rem 1.5rem;
  color: #FFF;
}

.video__button-icon {
  font-size: 1.2rem;
}

/*=============== faq ===============*/

.faq .accordion__container {
  max-width: 40rem;
  margin: 0 auto;
  
}

.faq .accordion__container .accordion {
  background: #fff;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          margin-left: 1rem;
          margin-right: 1rem;  
          border-radius: 2rem;  
}

.faq .accordion__container .accordion.active .accordion__heading {
  background: var(--first-color);
  border-radius: 2rem;
}

.faq .accordion__container .accordion.active .accordion__heading i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faq .accordion__container .accordion.active .accordioin__content {
  display: block;
}

.faq .accordion__container .accordion .accordion__heading {
  padding: 1rem;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: var(--title-color);
  cursor: pointer;
  border-radius: 2rem;
}

.faq .accordion__container .accordion .accordion__heading h3 {
  color: #fff;
}

.faq .accordion__container .accordion .accordion__heading i {
  font-size: 1.5rem;
  color: #fff;
}

.faq .accordion__container .accordioin__content {
  padding: 2rem;
  line-height: 2;
  display: none;
  -webkit-animation: fadeIn .2s linear;
          animation: fadeIn .2s linear;
}

/*=============== SUBSCRIBE ===============*/

.subscribe__bg {
  background-color: var(--first-color-second);
  padding: 2.5rem 0;
  display: none;
}

.subscribe__title,
.subscribe__description {
  color: var(--white-color);
}

.subscribe__description {
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.subscribe__form {
  background-color: var(--input-color);
  padding: .5rem;
  display: flex;
  justify-content: space-between;
  
}
.subscribe__form__presu {
  padding:1rem;
  
}


.subscribe__input {
  width: 70%;
  padding-right: .5rem;
  background-color: whitesmoke;
  color: var(--text-color);
  
}
.subscribe__input__presu {
  width: 100%;
  height: 2.5rem;
  color: var(--text-color);
  padding: 0.5rem;
  background-color: whitesmoke;
  border-left: 2px solid green;
}
.subscribe__textarea{
  width: 100%;
  height: 6rem;
  color: var(--text-color);
  padding: 0.5rem;
  background-color: whitesmoke;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-family: inherit;
  font-size: 1rem;
}
.select_presu{
  width: 100%;
  color: var(--text-color);
  padding: 0.5rem;
  background-color: whitesmoke;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  font-family: inherit;
  font-size: 1rem;
  border-left: 2px solid green;
}


.subscribe__input::placeholder {
  color: var(--text-color);
}
/*=============== REGISTRAR ===============*/
.registrar__form {
 
  display: grid;
  justify-content:inherit;
}

.registrar__input {
 
  margin-top: 1rem;
margin-bottom: 1rem;
}

.social {
  border: 1px solid  #FFF;
	border-radius: 50%;
	display: inline-flex;
	margin: 0 5px;
	height: 40px;
	width: 40px;
  justify-content: center;
	align-items:center;
  color: #FFF;
 
}
.social-container {
	margin-top: 1rem;
  margin-bottom: 1rem;
  
}
.keke{
	display: flex;
	
	flex-direction: column;
	text-align: center;
}

.pass{
  padding: 2rem;;
  font-size:14px;
  color: #FFF;
}
.error{
  color:tomato;
  font-size: 14px;
  margin-top: 10px;
}
.error1{
  color:#FFF;
  font-size: 14px;
  margin-top: 10px;
  justify-content: center;
  text-align: center;
  display: block;
}
.exit{
  display: none;
}

.cerrar{
  color: tomato;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.8;
}
.cerrar:hover{
  opacity: 1;
}
.links_login{
  display: inline-flex;
  justify-content: center;
}

/*=============== SPONSORS ===============*/
.sponsor__container {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  justify-items: center;
  row-gap: 3.5rem;
}



.sponsor__img {
  width: 100px;
  
  transition: var(--img-transition);
}

/*=============== FOOTER ===============*/
.maps{
  width: 100%;
  height: 15rem;
  border: 1px solid var(--first-color) ;
  margin-top: 2rem;
  
}
.footer__container {
  row-gap: 5rem;
}

.footer__content {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 2rem;
}

.footer__title,
.footer__subtitle {
  font-size: var(--h3-font-size);
}

.footer__title {
  margin-bottom: var(--mb-0-5);
}

.footer__description {
  margin-bottom: var(--mb-2);
}

.footer__social {
  font-size: 1.25rem;
  color: var(--title-color);
  margin-right: var(--mb-1-25);
}

.footer__subtitle {
  margin-bottom: var(--mb-1);
}

.footer__item {
  margin-bottom: var(--mb-0-75);
}

.footer__link {
  color: var(--text-color);
}

.footer__link:hover {
  color: var(--title-color);
}

.footer__rights {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  text-align: center;
}

.footer__copy,
.footer__terms-link {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.footer__terms {
  display: flex;
  column-gap: 1.5rem;
  justify-content: center;
}

.footer__terms-link:hover {
  color: var(--text-color);
}

/*========== SCROLL UP ==========*/


.scrollup {
  display: flex;
  padding: .5rem;
  font-weight: 700;
  color: var(--white-color);
  cursor: pointer;
  position: fixed;
  background-color:#25d366;
  text-decoration: none;
  overflow: hidden;
  z-index: var(--z-tooltip);
  font-family: inherit;
  right: 2rem;
  bottom: -20%;
  opacity: .9;
  transition: .4s;
  width:60px;
	height:60px;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  bottom: 5rem;
 }
 
 .scrollup:hover {
	text-decoration: none;
	color: #25d366;
  background-color:#fff;
}
 
 .scrollup:hover::before {
  transform: translateX(0);
 }

 .my-float{
    margin-top: 3px;
    margin-left: 10px;
}


/* Show scroll */
.show-scroll {
  bottom: 5rem;
  
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: .60rem;
  background-color: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

/*=============== MEDIA QUERIES ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .place__container {
    grid-template-columns: max-content;
    justify-content: center;
  }

  .experience__content {
    padding: 0;
  }

  .experience__overlay:nth-child(1) {
    width: 190px;
  }

  .experience__overlay:nth-child(2) {
    width: 80px;
  }

  .home__info {
    width: 190px;
    padding: 1rem;
  }

  .experience__img,
  .video__container {
    padding: 0;
  }

}

/* For medium devices */
@media screen and (min-width: 568px) {
  .video__container {
    display: grid;
    grid-template-columns: .6fr;
    justify-content: center;
  }

  .place__container {
    grid-template-columns: repeat(3, max-content);
  }

  .subscribe__form {
    width: 470px;
    margin: 0 auto;
  }



}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__link {
    color: var(--title-color);
    text-transform: initial;
  }

  .nav__link:hover {
    color: var(--text-color);
  }

  .nav__dark {
    position: initial;
  }

  .nav__menu {
    display: flex;
    column-gap: 1rem;
    margin-left: auto ;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 1.5rem;
    
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .change-theme-name {
    display: none;
  }

  .change-theme {
    color: var(--white-color);
  }

  .active-link::before {
    background-color: var(--white-color);
  }

 

  .scroll-header .active-link {
    color: var(--title-color);
  }

  .scroll-header .active-link::before {
    background-color: var(--title-color);
  }

  .scroll-header .change-theme {
    color: var(--text-color);
  }

  .section {
    padding: 7rem 0 2rem;
  }

  .home__container {
    height: 100vh;
    grid-template-rows: 1.8fr .5fr;
  }

  .home__data {
    align-self: flex-end;
  }

  .home__social {
    flex-direction: row;
    align-self: flex-end;
    margin-bottom: 3rem;
    column-gap: 2.5rem;
  }

  .home__info {
    bottom: 3rem;
  }

  .about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .about__data,
  .about__title {
    text-align: initial;
  }

  .about__title {
    margin-bottom: var(--mb-1-5);
  }

  .about__description {
    margin-bottom: var(--mb-2);
  }


  .discover__container,
  .place__container {
    padding-top: 2rem;
  }


  .experience__overlay:nth-child(1) {
    width: 363px;
    margin-right: 4rem;
  }

  .experience__overlay:nth-child(2) {
    width: 160px;
  }

  .subscribe__bg {
    background: none;
    padding: 0;
   
  }

  .subscribe__container {
    background-color: var(--first-color-second);
    padding: 3.5rem 0;
  }
  .form__container {
    padding: 3.5rem 0;
    border-top: 2px solid hsl(197,36%,9%);
    
  }
  .subscribe__input {
    padding: 0 .5rem;
  }

  .footer__rights {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {

  .faq{
    padding: 3rem 2rem;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .home__container {
    grid-template-rows: 2fr .5fr;
  }

  .home__info {
    width: 328px;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
  }

  .home__info-title {
    font-size: var(--normal-font-size);
  }

  .home__info-img {
    width: 240px;
  }

  .about__img-one {
    width: 230px;
  }

  .about__img-two {
    width: 400px;
    border-radius: 0.5rem;
  }

  .discover__card {
    width: 300px;
    height: 200px;
    border-radius: 2rem;
    cursor: pointer;
  }
  .discover__card:hover {
   translate: 2px;
  }

  .discover__data {
    left: 1rem;
    bottom: 2rem;
    padding: 0;
  }
  
  .discover__title {
    font-size: var(--h2-font-size);
  }
  .discover__description{
    margin-top: 50px;
    padding: 5px;
  }
  .discover__button{
    margin-top: 70px;
    
   }

  .experience__content {
    margin: var(--mb-1) 0;
    column-gap: 3.5rem;
  }

  .experience__overlay:nth-child(1) {
    width: 463px;
    margin-right: 7rem;
  }

  .experience__overlay:nth-child(2) {
    width: 220px;
    top: 3rem;
  }

  .video__container {
    grid-template-columns: .7fr;
  }

  .video__description {
    padding: 0 8rem;
  }

  .place__container {
    gap: 3rem 2rem;
  }
  .place__content{
    padding: 2rem;
  }

  .place__card,
  .place__img {
    height: 263px;
  }

  .sponsor__img {
    width: 150px;
    
    transition: var(--img-transition);
  }
  .footer__content {
    justify-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1024px;
  }

  .nav__logo {
    max-width: 10rem;
    margin-top: 1rem;
  }
}

/* For tall screens on mobiles y desktop*/
@media screen and (min-height: 721px) {
  body {
    margin: 0;
  }

  .home__container,
  .home__img {
    height: 640px;
  }
  .nav__logo {
    max-width: 9rem;
    margin-top: 1rem;
  }
}